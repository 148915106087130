<template>
  <warehouse-form
    ref="warehouse-form"
    :is-create-form="true"
  />
</template>

<script>

import WarehouseForm from '../components/WarehouseForm.vue'

export default {
  name: 'WarehouseCreate',
  components: {
    WarehouseForm,
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['warehouse-form']) {
      this.$refs['warehouse-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  created() {
    const initialData = { status: false, locations: [] }

    this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, initialData)
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_WAREHOUSE_FORM_CLONE`, initialData)
  },
  setup() {
    const MODULE_NAME = 'warehouse'
    const MODULE_NAME_CLONE = 'cloneData'

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>

<style>

</style>
